@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

* {
  font-size: 16px;
}

.App {
  //only mobile
  @media (max-width: 768px) {
    background-color: #fbfbfb;
    background-image: none;
  }
  background-image: linear-gradient(180deg, #fbfbfb 36vh, #f2f2f2 36vh, #f2f2f2 101vh, #ebebeb 101vh);
  min-height: 100vh;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

@-webkit-keyframes Vertical {
  0% {
    background-position: 48% 0%;
  }
  50% {
    background-position: 53% 100%;
  }
  100% {
    background-position: 48% 0%;
  }
}

@-moz-keyframes Vertical {
  0% {
    background-position: 48% 0%;
  }
  50% {
    background-position: 53% 100%;
  }
  100% {
    background-position: 48% 0%;
  }
}

@keyframes Vertical {
  0% {
    background-position: 48% 0%;
  }
  50% {
    background-position: 53% 100%;
  }
  100% {
    background-position: 48% 0%;
  }
}

$color-primary: #520fec;
$color-secondary: #44ffd1;
$color-gray: #525252;
$color-outrangeYellow: #f5ac37;

.bg-primary {
  background-color: $color-primary !important;
}

.bg-first {
  background-color: $color-primary !important;
}

.bg-second,
.btn-secondary {
  background-color: $color-secondary !important;
}

.bg-module-option {
  background-color: $color-secondary !important;
}

.bg-module-option:hover {
  background-color: $color-primary !important;
  box-shadow: 0 0 3px 3px #44fed1;
}

.text-module-option {
  color: $color-primary !important;
}

.text-module-option:hover {
  color: white !important;
}

.bg-secondary-opacity:hover {
  background-color: $color-secondary !important;
  opacity: 0.75 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-purple-2 {
  background-color: #3a0ca3 !important;
}

.primary {
  color: $color-primary !important;
}

.secondary {
  color: $color-secondary !important;
}

.text-gray {
  color: $color-gray !important;
}

.text-light-gray {
  color: #f2f2f2 !important;
}

.background-light-gray {
  background-color: #f2f2f2 !important;
}

.border-primary {
  border-color: $color-primary !important;
}

.border-gray {
  border-color: $color-gray !important;
  border: 1px solid;
}

.no-underline {
  text-decoration: none !important;
}

.selected-secondary {
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 3px !important;
  color: $color-secondary !important;
}

.underline-secondary:hover {
  text-decoration: underline !important;
  text-decoration-thickness: 3px !important;
  text-underline-offset: 3px !important;
  color: $color-secondary !important;
}

.azeret {
  font-family: 'Azeret Mono';
}

.azeret-md {
  font-family: 'Azeret Mono';
  font-weight: 500 !important;
}

.azeret-semibold {
  font-family: 'Azeret Mono';
  font-weight: 600 !important;
}

.azeret-bold {
  font-family: 'Azeret Mono';
  font-weight: 700 !important;
}

.icon-shadow {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
  border-radius: 25px;
}

.opacity-md:hover {
  opacity: 0.75 !important;
}

.no-outline {
  outline: none !important;
}

.fill-btn {
  fill: $color-primary;
}

.stroke-btn {
  stroke: $color-primary;
}
.betaHeader {
  background-color: $color-outrangeYellow;
  font-family: 'Azeret Mono';
}

//only for desktop
@media (min-width: 768px) {
  #box-scrollbar::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
    border: 2px solid $color-primary; /* creates padding around scroll thumb */
    border-radius: 20px;
    margin-left: 1rem;
  }

  #box-scrollbar::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
    border: 2px solid $color-primary; /* creates padding around scroll thumb */
    border-radius: 20px;
  }

  #box-scrollbar::-webkit-scrollbar-thumb {
    background-color: $color-primary; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 2px solid $color-primary; /* creates padding around scroll thumb */
  }
}
#box-scrollbar {
  overflow-x: hidden;
}

@media (max-width: 768px) {
  #box-scrollbar {
    overflow-y: hidden;
  }
}

.right-1 {
  right: 1rem;
}

.w-28 {
  width: 28rem;
}

@media (max-width: 768px) {
  .w-mobile-100 {
    width: 100%;
  }
}

.h-7 {
  height: 1.7rem;
}

.border-gray-palette {
  border-color: #525252;
}
