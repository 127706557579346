@import './src/styles/colors.scss';
@import './src/styles/App.scss';
@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-sun {
  background: url('../../images/bg-sun.png') no-repeat center center;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 768px) {
    background: url('../../images/bg-sun-mobile.png') no-repeat center center;
    background-size: cover;
  }
}

.desktop {
  display: block !important;
  @media only screen and (max-width: 990px) {
    display: none !important;
  }
}

.vault {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* problem with vault connector from 990 to 1170 */
.mobile {
  display: none !important;
  @media only screen and (max-width: 990px) {
    display: block !important;
  }
}

.place-items-center {
  @media only screen and (max-width: 990px) {
    place-items: center !important;
  }
}

.bg-mountains {
  background: url('../../images/bg-mountains.png') no-repeat center center;
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 768px) {
    background: url('../../images/bg-mountains-mobile.png') no-repeat center center;
    padding-bottom: 10rem;
    background-size: cover;
  }
}

.bg-gradient {
  background: linear-gradient(180deg, #3a0ca3 20.31%, #000b28 100%);
}

.last-point {
  position: absolute !important;
  padding-top: 6rem !important;
}

.border-secondary {
  border-color: $color-secondary !important;
}

.work-sans {
  font-family: 'Work Sans' !important;
  font-weight: 900;
}

.container {
  max-width: 1140px !important;
  @media only screen and (max-width: 1170px) {
    max-width: 1065px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 580px;
  }
  @media only screen and (max-width: 640px) {
    max-width: 370px;
  }
}

.m-l-neg-2 {
  margin-left: -2.5rem !important;
}

.w-900 {
  font-weight: 900 !important;
}

.h1 {
  font-size: 4.5rem !important;
  font-family: 'Work Sans' !important;
  font-weight: 900;
  line-height: 5rem;

  @media only screen and (max-width: 768px) {
    font-size: 3rem;
    line-height: 3.2rem;
  }
}

.work-sans {
  font-family: 'Work Sans' !important;
  font-weight: 900 !important;
  line-height: 1.5rem !important;
}

.work-sans-normal {
  font-family: 'Work Sans' !important;
  font-weight: 500 !important;
}

.work-sans-light {
  font-family: 'Work Sans' !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.pair-header {
  font-size: 20px;
  line-height: 1.2;
}

.pill-shadow {
  box-shadow: 0px 0px 10px 0px #00ffd1;
}

ul {
  list-style: none; /* Remove default bullets */
  @media only screen and (max-width: 768px) {
    padding-left: 1.5rem;
    margin-right: 3rem;
  }
}

.small-text {
  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.mb-perc {
  @media only screen and (max-width: 768px) {
    margin-bottom: 45%;
  }
}

.mb-less {
  @media only screen and (max-width: 768px) {
    margin-bottom: 25%;
  }
}

.mx-medium {
  margin-left: 5rem;
  margin-right: 5rem;
}

.pointer {
  cursor: pointer;
}

li > p {
  font-size: 1.4rem;
  font-weight: 900;
  padding-right: 2rem;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    padding-right: 0rem;
  }
}

@media only screen and (min-width: 768px) {
  .mt-8 {
    margin-top: 5rem;
  }
}

.mb-6 {
  margin-bottom: 3rem;
}

.mb-8 {
  margin-bottom: 5rem !important;
}

.text-icon {
  padding-top: 0.4rem;
  padding-left: 0.5rem;
  font-size: 0.8rem;
  font-weight: 700;
}

.card {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2.4rem;
  padding-left: 1.5rem;
  border-radius: 1rem;
  min-height: 15rem;
  max-width: 22.5rem;
  color: white;
}

.cardOpen {
  width: auto;
  height: fit-content;
  min-height: fit-content;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2.4rem;
  padding-left: 1.5rem;
  border-radius: 1rem;
  border: 1px solid;
  color: white;
  margin-bottom: 20px;
}

.text-box {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 1.2rem;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
}

.text-box-less-px {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  border-radius: 1.2rem;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
}

.text-box-white {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  border-radius: 1.2rem;
  background-color: white;
  color: #525252;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
}

.shadow {
  box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.15);
}

.small {
  font-size: 1.1rem;
  margin-bottom: 0.3rem;
  @media only screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
}

// create an ellipse
.ellipse {
  width: 150%;
  height: 137%;
  box-shadow: 0px 4px 20px $color-primary;
  border-radius: 50%;
  position: fixed;
  border-color: $color-primary;
  border: 40px solid $color-primary;
  top: -28%;
  left: -15%;
  background-color: transparent;
  transform: rotate(160deg);
}

.launch-button {
  justify-content: center;
  align-content: center;
  height: 2.5rem;
  font-size: 1.1rem;
  min-width: 12rem;
  font-weight: bold;
  color: $color-primary;
  background-color: $color-secondary;
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
    min-width: 3rem;
  }
}

.lg {
  font-size: 1.5rem;
}

.launch-button:hover {
  background-color: $color-primary;
  box-shadow: 0 0 4px 3px $color-secondary;
  color: white;
}

.work-sans-bold {
  font-family: 'Work Sans' !important;
  font-weight: 700 !important;
}

.email-input {
  border-color: $color-primary;
  background-color: #000b28;
  color: white !important;
  padding: 1rem;
}

.reserved {
  color: grey;
  font-size: 0.8rem;
}

#my-email.form-control:focus {
  border-color: $color-primary;
  background-color: #000b28;
}

#my-email.form-control::placeholder {
  color: white;
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.divider {
  border-left: 3px solid $color-secondary;
  width: 1rem;
  height: 12rem;
}

.border-secondary-right {
  border-right: 3px solid $color-secondary;
  //if mobile set border-right to none
  @media only screen and (min-width: 768px) and (max-width: 820px) {
    border-right: none;
  }
}

